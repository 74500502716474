import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section, Image, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				PureClean
			</title>
			<meta name={"description"} content={"Там, где сияет ваш автомобиль"} />
			<meta property={"og:title"} content={"PureClean"} />
			<meta property={"og:description"} content={"Там, где сияет ваш автомобиль"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-4.jpg?v=2024-06-17T09:22:09.744Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="linear-gradient(0deg,rgba(180, 173, 173, 0.38) 0%,#e8e4f4 99%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/2-3.jpg?v=2024-06-17T09:22:09.772Z) center/cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="85%"
					display="flex"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Ознакомьтесь с нашими услугами
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--greyD3" md-text-align="center">
						<Strong>
							В автомойке PureClean мы не просто моем ваш автомобиль – мы обеспечиваем комплексный режим ухода, гарантирующий, что ваш автомобиль будет не только чистым, но и защищенным и ухоженным. Наши передовые технологии и высококачественные продукты удовлетворяют всем аспектам ухода за автомобилем, гарантируя, что ваш автомобиль покидает наши помещения выглядя и чувствуя себя как новый.
						</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" lg-padding="60px 0 60px 0" md-padding="40px 0 40px 0" sm-padding="30px 0 30px 0">
			<Box margin="0px 0px 32px 0px" display="flex" flex-direction="column" align-items="flex-start">
				<Text font="--headline3" color="--darkL2" max-width="90%">
					PureClean предлагает ряд специализированных услуг, предназначенных для удовлетворения потребностей каждого автолюбителя и ежедневного водителя. Откройте для себя совершенство, которое мы предлагаем:
				</Text>
			</Box>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10:50:58.126Z"
						border-radius="24px"
						max-width="100%"
						max-height="600px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-4.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Text margin="0px 0px 2rem 0px" font="--headline2" color="--dark">
						Комплексные решения для очистки
					</Text>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
							Внешняя мойка
							<br />
							<br />
							Мойка ходовой части
							<br />
							<br />
							{" "}Очистка колес
							<br />
							<br />
							Детали интерьера
							<br />
							<br />
							Глубокий вакуум
							<br />
							<br />
							Очистка обивки
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09:22:09.760Z"
						border-radius="24px"
						max-width="100%"
						max-height="600px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						object-position="70% 50%"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666ffff68d4a0c002030887d/images/1-2.jpg?v=2024-06-17T09%3A22%3A09.760Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%" align-self="center">
						<Text margin="0px 0px 2rem 0px" font="--headline2" color="--dark">
							Специализированные процедуры
						</Text>
						<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
							Воск и полироль{"\n"}
							<br />
							<br />
							{"\n"}Защита краски
							<br />
							<br />
							{"\n\n"}Керамическое покрытие{"\n"}
							<br />
							<br />
							{"\n"}Очистка двигателя{"\n"}
							<br />
							<br />
							{"\n"}Обезжиривание{"\n"}
							<br />
							<br />
							{"\n"}Защитное покрытие
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10:50:58.126Z"
						border-radius="24px"
						max-width="100%"
						max-height="600px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07ad8e2e8e00217ce1a6/images/2-3.jpg?v=2024-06-14T10%3A50%3A58.126Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 2rem 0px" font="--headline2" color="--dark">
							Детализация и реставрация
						</Text>
						<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
							Восстановление фар
							<br />
							<br />
							{"\n\n"}Устранение запаха
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				/>
			</LinkBox>
		</Section>
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.6) 0%,rgba(4, 8, 12, 0.6) 100%),--color-darkL2 url(https://uploads.quarkly.io/666ffff68d4a0c002030887d/images/1-3.jpg?v=2024-06-17T09:22:09.775Z) center/cover"
			padding="140px 0 140px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Stack>
				<StackItem width="75%" lg-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
						ОТКРОЙТЕ ДЛЯ СЕБЯ ПОЛНЫЙ СПЕКТР НАШИХ ПРЕДЛОЖЕНИЙ
					</Text>
					<Text as="h1" font="--headline3" md-font="--headline2" margin="10px 0">
						Этот обзор – только начало. Посетите нас или свяжитесь с нашей командой, чтобы ознакомиться с полным спектром услуг, которые мы предлагаем, и узнать, как мы можем улучшить заботу о вашем автомобиле.
					</Text>
				</StackItem>
			</Stack>
		</Section>
		<Components.Footer>
			<Override slot="text3">
				{" "}PureClean Car Wash
			</Override>
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac377f0cb1d0025f631c9"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});